import api from "../axios_service.js";

// Busca Todas Redes Sociais
export async function fetchRedesSociais(filtros) {
  const response = await api.get(`/gestor/redes-sociais${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca uma Rede Social
export async function fetchRedeSocial(id) {
  const response = await api.get(`/gestor/redes-sociais/${id}`);
  return response.data.result;
}

// Atualiza uma Rede Social
export function putRedeSocial(id, social) {
  let body = {};
  for (let key in social) {
    body[key] = social[key];
  }
  return api.put(`/gestor/redes-sociais/${id}`, body);
}

// Cria uma nova Rede Social
export function postRedeSocial(social) {
  let body = {};
  for (let key in social) {
    body[key] = social[key];
  }
  return api.post("/gestor/redes-sociais/add", body);
}
